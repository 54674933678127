import { Environments } from './environments.interface';

const isProd: any = 'false';

export const environment: Environments = {
  production: Boolean(isProd === 'true'),
  API_URL: {
    RECORDS: 'https://api-sbx.sidedrawersbx.com/api/v1/records',
    SUBSCRIPTIONS: 'https://api-sbx.sidedrawersbx.com/api/v1/subscriptions',
    CONFIGS: 'https://config-api-sbx.sidedrawersbx.com/api/v1',
    USERS: 'https://api-sbx.sidedrawersbx.com/api/v1/users',
    DEVELOPER: 'https://api-sbx.sidedrawersbx.com/api/v1/developers',
    INTEGRATION: 'https://api-sbx.sidedrawersbx.com/api/v1/integrations',
    PLANS: 'https://api-sbx.sidedrawersbx.com/api/v1/planrequests',
    TENANTS: 'https://api-sbx.sidedrawersbx.com/api/v1/tenants',
  },
  AUTH0: {
    DOMAIN: 'auth-sbx.sidedrawersbx.com',
    CLIENT_ID: 'gisrH8Uhl0oXiRpjpvOKW7d5trf2RWlK',
    AUDIENCE: 'https://user-api-sbx.sidedrawersbx.com',
  },
  SD_TENANT_ID: '5f56d5526a292b86bb4c853a', // Get this value from sdrw-developer-api MongoDB collection.
};
